import { ProjectTree } from '@/api/project'

/**
 * Функция для получения дочерних узлов дерева.
 *
 * @param treeNode Узел дерева.
 * @returns Массив дочерних узлов.
 */
const getChildren = (treeNode: ProjectTree): ProjectTree[] => {
  return treeNode.childs?.length ? treeNode.childs : []
}

/**
 * Функция для объединения данных в plugin_data, возвращает массив объектов.
 *
 * @param array Массив объектов для объединения.
 * @returns Массив объектов типа MergedObject.
 */
const mergeObjects = (array: Record<string, unknown>[]): MergedObject[] => {
  return array.flatMap(
    obj =>
      Object.values(obj).filter(
        value => typeof value === 'object' && value !== null,
      ) as MergedObject[],
  )
}

/**
 * Функция для получения данных полей на основе метаданных.
 *
 * @param nodePluginFields Плагинные данные узла.
 * @param metaLayers Метаданные слоев проекта.
 * @returns Объект, где ключом является название поля, а значением - объект поля типа ITreeFieldType.
 */
const getNodeFields = (
  nodePluginFields: MergedObject[],
  metaLayers: FieldType[],
): NodeFieldsType => {
  return nodePluginFields.reduce((acc, field) => {
    const fieldMeta = metaLayers.find(m => m.id === field.field_id)
    if (fieldMeta?.name) {
      acc[fieldMeta.name] = { ...fieldMeta, value: field.value }
    }
    return acc
  }, {} as NodeFieldsType)
}

/**
 * Функция для создания объекта узла дерева.
 *
 * @param treeNode Узел дерева.
 * @param nodeFields Поля узла.
 * @returns Объект типа TreeNodeData.
 */
const createNodeData = (
  treeNode: ProjectTree,
  nodeFields: NodeFieldsType,
): TreeNodeData => {
  return {
    ...treeNode,
    id: Number(treeNode.id),
    parent: treeNode.parent_id,
    ownView: treeNode.own_view,
    fields: nodeFields,
    childs: [],
  }
}

/**
 * Функция для извлечения всех полей всех типов слоев и их объединения в одномерный массив.
 *
 * @param meta Метаданные узлов проекта.
 * @returns Массив объектов типа FieldType.
 */
const extractMetaFields = (meta: MetaType): FieldType[] => {
  return Object.values(meta).flatMap(layerTypeMeta =>
    Object.values(layerTypeMeta.plugin_data).flatMap(
      pluginData => pluginData?.fields || [],
    ),
  )
}

/**
 * Функция для форматирования узла дерева.
 *
 * @param treeNode Узел дерева (слой).
 * @param metaLayers Метаданные слоев проекта.
 * @param flatMode Если true, возвращает одномерный массив узлов; если false, возвращает иерархическую структуру.
 * @returns Отформатированные узлы дерева типа TreeNodeData.
 */
const formatTreeNode = (
  treeNode: ProjectTree,
  metaLayers: FieldType[],
  flatMode: boolean,
): TreeNodeData | TreeNodeData[] => {
  const nodePluginData = Object.values(treeNode.plugin_data || {}) as Record<
    string,
    unknown
  >[]
  const nodePluginFields = mergeObjects(nodePluginData)
  const nodeFields = getNodeFields(nodePluginFields, metaLayers)

  const nodeData = createNodeData(treeNode, nodeFields)
  const children = getChildren(treeNode)

  if (flatMode) {
    return children.length
      ? [
          nodeData,
          ...children.flatMap(
            child =>
              formatTreeNode(child, metaLayers, flatMode) as TreeNodeData[],
          ),
        ]
      : [nodeData]
  } else {
    if (children.length) {
      nodeData.childs = children.map(
        child => formatTreeNode(child, metaLayers, flatMode) as TreeNodeData,
      )
    }
    return nodeData
  }
}

/**
 * Функция для формирования дерева проекта.
 *
 * @param treeNodes Массив с деревом проекта.
 * @param meta Метаданные узлов проекта.
 * @param flatMode Если true, возвращает одномерный массив узлов; если false, возвращает иерархическую структуру.
 * @returns Отформатированные узлы проекта типа TreeNodeData.
 */
const formatProjectTree = (
  treeNodes: ProjectTree[],
  meta: MetaType,
  flatMode: boolean,
): TreeNodeData[] => {
  if (!treeNodes.length) return []

  const metaFields = extractMetaFields(meta)
  return flatMode
    ? treeNodes.flatMap(
        item => formatTreeNode(item, metaFields, flatMode) as TreeNodeData[],
      )
    : treeNodes.map(
        item => formatTreeNode(item, metaFields, flatMode) as TreeNodeData,
      )
}

/**
 * Функция для формирования одномерного массива со всеми слоями и их полями.
 *
 * @param treeNodes Массив с деревом проекта.
 * @param meta Метаданные узлов проекта.
 * @returns Отформатированные узлы проекта типа TreeNodeData.
 */
export const formatProjectTreeFlat = (
  treeNodes: ProjectTree[],
  meta: MetaType,
): TreeNodeData[] => formatProjectTree(treeNodes, meta, true)

/**
 * Функция для формирования дерева со всеми слоями и их полями в иерархическом виде.
 *
 * @param treeNodes Массив с деревом проекта.
 * @param meta Метаданные узлов проекта.
 * @returns Отформатированные узлы проекта типа TreeNodeData.
 */
export const formatProjectTreeStructured = (
  treeNodes: ProjectTree[],
  meta: MetaType,
): TreeNodeData[] => formatProjectTree(treeNodes, meta, false)

/**
 * Функция для поиска ближайшего слоя, который имеет собственное представление (ownView).
 *
 * @param layers Массив узлов дерева проекта.
 * @returns Первый найденный узел с собственным представлением, или null, если такого узла нет.
 */
export const getNearestViewableLayer = (layers: TreeNodeData[]) => {
  return layers.find(node => node.ownView) || null
}

/**
 * Функция для поиска слоя с собственным представлением, начиная с заданного идентификатора узла.
 * Если идентификатор не указан, возвращает ближайший слой с собственным представлением.
 *
 * @param layers Массив узлов дерева проекта.
 * @param referenceId Идентификатор узла, с которого начинается поиск. Если не указан, будет найден первый узел с собственным представлением.
 * @returns Найденный узел с собственным представлением или null, если такого узла не найдено.
 */
export const findViewableLayer = (
  layers: TreeNodeData[],
  referenceId?: number | null,
) => {
  if (!referenceId) return getNearestViewableLayer(layers)
  const findNode = (requestedId: number): TreeNodeData | null => {
    const currentLayer = layers.find(l => l.id === requestedId)

    if (!currentLayer) return null
    if (currentLayer.ownView) return currentLayer
    if (!currentLayer.parent) return null
    return findNode(currentLayer.parent)
  }

  const viewableNode = findNode(referenceId)
  return viewableNode ?? getNearestViewableLayer(layers)
}

type MergedObject = {
  field_id: number
  type_id: string
  value: unknown
}

export type FieldType = {
  type_name: string
  id: number
  type_id: string
  name: string
  sort: number
  obligate: boolean
  lister: boolean
  extension: string
}

export type ITreeFieldType = FieldType & { value: unknown }
export type PluginData = {
  fields: FieldType[]
}

type MetaType = Record<
  string,
  {
    plugin_data: Record<string, PluginData>
  }
>
export type NodeFieldsType = Record<string, ITreeFieldType>
export type TreeNodeData = Omit<ProjectTree, 'childs'> & {
  id: number
  name: string
  parent: number | null
  ownView: boolean
  fields: NodeFieldsType
  childs: TreeNodeData[]
}
