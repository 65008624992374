import React from 'react'
import styled from 'styled-components'
import UserBar from './user/UserBar';
import media from '@/ui/media';
import SearchBar from './search/SearchBar';
import DateSelectorBar from './booking-filter/DateSelectorBar';

const Appbar: React.FC = () => {
    return (
        <Wrapper>
            <SearchBar />
            <DateSelectorBar />
            <UserBar />
        </Wrapper>
    )
}

export default Appbar

const Wrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 32px;
    padding: 0 20px;
    height: 75px;
    box-shadow: 4px 0px 40px rgba(84, 84, 84, 0.25);
    /* border-radius: 0px 0px 8px 8px; */
    z-index: 2001;
    background: #FF7221;
    color: ${props => props.theme.palette.text.primary};

    ${media.lg`
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 16px;
        padding: 0 10px;
        border-radius: 0px;
    `}
`