import React, { useEffect, useMemo } from 'react'
import MapStage from '@/components/shared/map/stage/MapStage'
import MapCoverLayer from './MapCoverLayer'
import PolygonsLayer, { filterHiddenFromMap } from '../polygon/PolygonsLayer'
import PointsLayer from '../point/PointsLayer'
import { useLayerView } from '@/api/hooks/useLayerView'
import { useMetadata } from '@/api/hooks/useMetadata'
import { useBookingsForLayer } from '@/api/hooks/useBookingsForLayer'
import { useGlobalStore } from '@/stores/globalStore'
import { useProjectStore } from '@/stores/projectStore'
import { useMapStore } from '@/stores/mapStore'
import { formatLocalDateToAPI } from '@/utils/helpers/dates.helpers'
import { addMinutes } from 'date-fns'
import Tooltip from '../tooltip/Tooltip'
import { useUserCardFields } from '@/api/hooks/useUserCardFields'
import { useTree } from '@/api/hooks/useTree'
import { useToast } from '../../toast/useToast'
import useSettings from '../../../../hooks/settings/use-settings'
import { findViewableLayer } from '@/components/Elements/tree'
import { useUserStore } from '@/stores/userStore'
import { gatherHiddenLayers } from '@/components/layout/AppBar/search/Search'

const dispatchLoaded = () => {
  const event = new CustomEvent('map-loaded')
  document.dispatchEvent(event)
}

const MapContainer = () => {
  const { enqueueToast } = useToast()
  const activeLayer = useGlobalStore(state => state.activeLayer)
  const selection = useGlobalStore(state => state.selection)
  const nodes = useProjectStore(state => state.nodes)
  const setMapLayer = useMapStore(state => state.setMapLayer)
  const node = nodes.find(n => n.id == activeLayer)
  const hasOwnView = node?.ownView
  const setActiveLayer = useGlobalStore(state => state.setActiveLayer)
  const layerId = hasOwnView ? node.id : node?.parent || activeLayer
  const {
    layerView,
    isSuccess: layerViewLoading,
    isLoading,
  } = useLayerView(Number(layerId))
  const { metadata } = useMetadata()
  const { data: settings } = useSettings()
  const { data: userFields } = useUserCardFields()
  const { data, isSuccess } = useBookingsForLayer(
    Number(layerId),
    formatLocalDateToAPI(selection.startDate),
    formatLocalDateToAPI(addMinutes(selection.endDate, -30)),
  )
  const { layers } = useTree()
  const role = useUserStore(state => state.role)
  const hiddenLayersIds = useMemo(
    () => gatherHiddenLayers(layers?.tree || [], role, filterHiddenFromMap),
    [layers?.tree, role],
  )

  const userDepartmentFieldId = useMemo(
    () =>
      userFields?.fields.find(field => field.label === '#department')?.uid ||
      null,
    [userFields],
  )

  /**
   *  если в слое нет подложки, то перекинет на слой в котором она есть,
   *  либо выдаст предупреждение, что таких слоев нет
   */
  useEffect(() => {
    if (isLoading || !layers || !layers?.nodes) return
    const nodes = layers.nodes
    const currentLayer = nodes.find(node => node.id === layerId)

    if (currentLayer && currentLayer.ownView) return
    const viewableLayer = findViewableLayer(nodes, layerId)

    if (!viewableLayer) {
      enqueueToast(
        `В проекте отсутствуют слои, которые имеют собственной подложки`,
        {
          variant: 'error',
        },
      )

      return
    }

    setActiveLayer(viewableLayer.id)
    enqueueToast(
      `Слой "${currentLayer?.name}" не имеет подложки. Открыт слой "${viewableLayer.name}"`,
      {
        variant: 'error',
      },
    )
  }, [layerView?.image])

  useEffect(() => {
    setMapLayer(Number(layerId))
  }, [layerId, activeLayer])

  useEffect(() => {
    if (isSuccess && layerViewLoading) {
      dispatchLoaded()
    }
  }, [isSuccess, layerViewLoading, activeLayer])

  const viewablePoints = useMemo(
    () => layerView?.points?.filter(p => !hiddenLayersIds.includes(p.parent)),
    [layerView?.points, hiddenLayersIds],
  )

  return (
    <MapStage>
      <MapCoverLayer view={layerView?.view} />
      <PolygonsLayer polygons={layerView?.polygons} />
      <PointsLayer
        userDepartmentFieldId={userDepartmentFieldId}
        colors={settings?.colors || {}}
        nodes={metadata?.rawNodes}
        points={viewablePoints}
        options={layerView?.options}
        bookings={data?.bookings}
      />
      <Tooltip />
    </MapStage>
  )
}

MapContainer.whyDidYouRender = true

export default MapContainer
