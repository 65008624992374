import { FC, MouseEvent, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import TreeList, { TreeListStyles } from './TreeList'
import MoreIcon from '@/components/icons/MoreIcon'
import useHover from '@/hooks/useHover'
import ArrowIcon from '@/components/icons/ArrowIcon'
import { useGlobalStore } from '@/stores/globalStore'
import useResponsive from '@/hooks/useResponsive'
import { NodeFieldsType, TreeNodeData } from '@/components/Elements/tree'
import { RolesEnum, useUserStore } from '@/stores/userStore'

interface TreeItemProps {
  tree: TreeNodeData
  expanded?: boolean
}

const checkHidden = (fields: NodeFieldsType, role: RolesEnum): boolean => {
  return (
    fields['#hidden-from-menu']?.value === true ||
    (role === RolesEnum.User && fields['#hidden-from-user']?.value === true)
  )
}

const TreeItem: FC<TreeItemProps> = ({ tree, expanded = false }) => {
  const ref = useRef(null)
  const role = useUserStore(state => state.role)
  const hover = useHover(ref)
  const { isMobile } = useResponsive()

  const { fields } = tree
  const isHidden = checkHidden(fields, role)
  const goTo = Number(fields['#goto']?.value) || null

  // data selectors
  const activeLayer = useGlobalStore(state => state.activeLayer)
  const setActiveLayer = useGlobalStore(state => state.setActiveLayer)
  const setLayerModal = useGlobalStore(state => state.setLayerModal)
  const setSelector = useGlobalStore(state => state.setSelector)

  const hasChildren = tree.childs && tree.childs.length
  const expandable = !!hasChildren
  const active = tree.id == activeLayer || hover

  // local state
  const [isExpanded, setExpanded] = useState<boolean>(expanded)

  const handleExpand = () => setExpanded(!isExpanded)
  const handleAbout = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    setLayerModal(tree.id)
  }

  const handleLayerChange = () => {
    if (isMobile) {
      setSelector(null)
    }

    const redirectId = goTo ?? tree.id
    setActiveLayer(redirectId)
  }

  if (isHidden) return null

  return (
    <ListItem>
      <ListName
        ref={ref}
        $active={active}
      >
        <NameWrapper onClick={handleLayerChange}>{tree.name}</NameWrapper>
        <ItemControls>
          {active && (
            <AboutButton onClick={handleAbout}>
              <MoreIcon />
            </AboutButton>
          )}
          {expandable && (
            <ExpandButton
              onClick={handleExpand}
              $expanded={isExpanded}
            >
              <ArrowIcon color="#fff" />
            </ExpandButton>
          )}
        </ItemControls>
      </ListName>
      <TreeList $expanded={isExpanded}>
        {tree.childs.map(child => (
          <TreeItem
            tree={child}
            key={child.id}
          />
        ))}
      </TreeList>
    </ListItem>
  )
}

export default TreeItem

const ItemControls = styled.div`
  display: flex;
  align-items: center;
`

const ExpandButton = styled.button<{ $expanded?: boolean }>`
  outline: none;
  border: none;
  background: transparent;
  transform: rotate(180deg);
  transition: transform 0.2s ease-in;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: rgba(0, 0, 0, 0.3);
  }

  ${({ $expanded }) =>
    $expanded &&
    css`
      transform: rotate(90deg);
    `}
`

const ListName = styled.div<{ $active?: boolean }>`
  /* padding: 8px; */
  min-height: 40px;
  border-radius: 8px;
  color: ${props => props.theme.palette.text.primary};
  position: relative;
  display: flex;
  justify-content: space-between;

  &:hover {
    color: ${props => props.theme.palette.accent.main};
    text-decoration: underline;
  }

  ${({ $active }) =>
    $active &&
    css`
      color: ${props => props.theme.palette.accent.main};
      text-decoration: underline;
    `}
`

const ListItem = styled.li`
  ${TreeListStyles} /* padding-top: 0.8rem; */ position: relative;
  cursor: pointer;
  min-height: 42px;
`

const AboutButton = styled.button`
  outline: none;
  border: none;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  background: #f8dc4d;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  /* top: 12px; */
  right: 42px;
`

const NameWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 60px;
  user-select: none;
  max-width: 300px;
`
