import { FC } from 'react'
import styled from 'styled-components'
import TreeItem from './TreeItem'
import TreeList from './TreeList'
import { TreeNodeData } from '@/components/Elements/tree'

type TreeViewProps = {
  tree: TreeNodeData[]
}

const TreeView: FC<TreeViewProps> = ({ tree }) => {
  if (!tree || !tree.length) return null

  return (
    <ListContainer>
      <TreeList $expanded={true}>
        {tree.map(item => (
          <TreeItem
            tree={item}
            key={item.id}
          />
        ))}
      </TreeList>
    </ListContainer>
  )
}

export default TreeView

const ListContainer = styled.div`
  padding: 0.8rem 0;
  overflow-y: auto;
  height: 100%;
`
