import { Layer } from 'react-konva'
import { memo, useEffect, useMemo } from 'react'
import Polygon from './Polygon'
import { useMapStore } from '@/stores/mapStore'
import { NodeFieldsType } from '@/components/Elements/tree'
import { RolesEnum, useUserStore } from '@/stores/userStore'

const dispatchPolygonsLoaded = () => {
  const event = new CustomEvent('polygons-loaded')
  document.dispatchEvent(event)
}

export const filterHiddenFromMap = (
  polygon: { fields: NodeFieldsType },
  role: RolesEnum,
): boolean => {
  return (
    polygon.fields['#hidden-from-map']?.value === true ||
    (role === RolesEnum.User &&
      polygon.fields['#hidden-from-user']?.value === true)
  )
}

const PolygonsLayer: React.FC<any> = ({ polygons = [] }) => {
  const layers = useMapStore(state => state.layers)
  const role = useUserStore(state => state.role)

  const isVisible = useMemo(() => layers['layers'], [layers])
  const [width, height] = useMapStore(state => state.size)

  useEffect(() => {
    dispatchPolygonsLoaded()
  }, [polygons])

  if (!isVisible) return null

  return (
    <Layer>
      {polygons
        .filter(p => !filterHiddenFromMap(p, role))
        .map(({ id, polygon, fields }) => (
          <Polygon
            id={id}
            key={id}
            width={width}
            height={height}
            polygon={polygon}
            fields={fields}
          />
        ))}
    </Layer>
  )
}

export default memo(PolygonsLayer)

// PolygonsLayer.whyDidYouRender = true;
