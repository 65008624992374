import { InputField } from '@/ui/components/Field/Input'
import { FormLabel } from '@/ui/components/Form/FormLabel'
import Grid from '@/ui/components/Grid'
import { Field, useFormikContext } from 'formik'
import React, { useState } from 'react'
import { Portal } from 'react-portal'
import { ModalSelectInputField } from '../shared/ModalSelectInputField'
import SelectSeatModal from './SelectSeatModal'
import { translate } from '@/i18n'
import { useIntl } from 'react-intl'

export const SelectSeatField = () => {
  const [isOpen, setOpen] = useState(false)
  const { setFieldValue } = useFormikContext()
  const intl = useIntl()

  return (
    <>
      <Grid
        item
        xs={12}
        md={4}
        onClick={() => setOpen(true)}
      >
        <FormLabel>{translate('choose-location')}</FormLabel>
        <Field
          name="seat"
          $fullWidth
          type="text"
          placeholder={intl.formatMessage({ id: 'location' })}
          disabled
          component={ModalSelectInputField}
          style={{ pointerEvents: 'none' }}
        />
      </Grid>
      <Portal>
        <SelectSeatModal
          isOpen={isOpen}
          setFieldValue={setFieldValue}
          onClose={() => setOpen(false)}
        />
      </Portal>
    </>
  )
}

export const SelectPlace = () => {
  const [isOpen, setOpen] = useState(false)
  const { setFieldValue } = useFormikContext()

  return (
    <>
      <Grid
        item
        xs={12}
        md={4}
        onClick={() => setOpen(true)}
      >
        <FormLabel>Выберите место</FormLabel>
        <Field
          name="spot"
          $fullWidth
          type="text"
          placeholder="место"
          disabled
          component={InputField}
        />
      </Grid>
      <Portal>
        <SelectSeatModal
          isOpen={isOpen}
          setFieldValue={setFieldValue}
          onClose={() => setOpen(false)}
        />
      </Portal>
    </>
  )
}
